import { createGlobalState } from "@vueuse/core";
import { usePreweddingStore } from "@/stores/prewedding";
import { useAppStateStore } from "@/stores/appState";
import Swal from "sweetalert2";


export default createGlobalState(() => {
  const preweddingStore = usePreweddingStore();
  const appStateStore = useAppStateStore();  

  return {
    onPreviewPrewedding,
    onUploadMultiImage,
    onRemoveMultiImage,
    onRemoveImage,
    onRemoveImageWithKey,
    nextIndexAt,
    cancelSelectImage,
    countTrueValues
  };

  function onPreviewPrewedding(prewedding, index, type="mobile") {
    const selected =  {
      imgUrl: prewedding.original,
      isPreview: true,
      index,
    };

    return selected
  }

  async function onUploadMultiImage(event, countTempUpload, tempUploadArray, uploadLoading) {
    const files = event.target.files;
    if (files.length == 0) {
      return;
    }

    const sumImage = preweddingStore.imageList.length + files.length;
    if (sumImage > preweddingStore.limitUpload) {
      appStateStore.useToast({
        text: "เกินลิมิตในการอัพโหลดรูป",
        type: "error",
      });
      return;
    }

    if (files.length >= 11) {
      appStateStore.useToast({
        text: "อัพโหลดต่อครั้ง 10 รูปเท่านั้น",
        type: "error",
      });
      return;
    }

    try {
      countTempUpload.value = parseInt(sumImage);
      tempUploadArray.value = Array.from({ length: files.length }, (_, i) => i);
      uploadLoading.value = { isOpen: true, length: files.length, indexAt: 0 };

      await preweddingStore.uploadBatchImage(files, (indexAt) => {
        nextIndexAt(indexAt, uploadLoading);
      });
      appStateStore.useToast({
        text: "Upload รูปภาพสำเร็จ",
        type: "success",
      });
    } catch (error) {
      console.log("error", error);
      appStateStore.useToast({
        text: "Upload ผิดพลาด กรุณาลองใหม่อีกครั้ง",
        type: "error",
      });
    }
  }

  async function onRemoveMultiImage(imageSelected, uploadLoading, isShowMultipleSelect) {
    const result = await Swal.fire({
      confirmButtonText: `remove`,
      confirmButtonColor: "#ef4444",
      cancelButtonText: "cancel",
      cancelButtonColor: "#6b7280",
      showCancelButton: true,
      text: `กดยืนยันเพื่อลบรูปภาพที่เลือก`,
      title: "แจ้งเตือน",
    });
    if (result.isDismissed == true) return;

    let fileLength = Object.keys(imageSelected.value).length;
    let count = 0;

    console.log(fileLength, count)
    uploadLoading.value = {
      isOpen: true,
      length: fileLength,
      indexAt: 0,
      text: "Remove",
    };
    for (const key in imageSelected.value) {
      console.log(key)
      if (imageSelected.value[key]) {
        nextIndexAt(count, uploadLoading);
        await onRemoveImageWithKey(key);
        delete imageSelected.value[key];
        count += 1;
      }
    }

    isShowMultipleSelect.value = false;
  }

  async function onRemoveImage(index) {
    const nameKey = preweddingStore.imageList[index].key;
    await onRemoveImageWithKey(nameKey);
  }

  async function onRemoveImageWithKey(nameKey) {
    try {
      await preweddingStore.removeImage(nameKey);
      appStateStore.useToast({
        text: "ลบรูปภาพสำเร็จ",
        type: "success",
      });
    } catch (error) {
      appStateStore.useToast({
        text: "ลบรูปผิดพลาด กรุณาลองใหม่อีกครั้ง",
        type: "error",
      });
    }
  }

  function nextIndexAt(index, uploadLoading) {
    uploadLoading.value.indexAt = index + 1;
    if (uploadLoading.value.indexAt == uploadLoading.value.length) {
      setTimeout(() => {
        uploadLoading.value.indexAt = 0;
        uploadLoading.value.isOpen = false;
        uploadLoading.value.length = 0;
        uploadLoading.value.text = "";
      }, 3000);
    }
  }

  function cancelSelectImage(isShowMultipleSelect, imageSelected) {
    isShowMultipleSelect.value = false;
    imageSelected.value = {};
  }

  function countTrueValues(obj) {
    let count = 0;
    for (let key in obj) {
      if (obj[key] === true) {
        count++;
      }
    }
    return count;
  }
});
